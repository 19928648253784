.container {
  max-width: 1200px;
}

.display-4 {
  font-weight: 300;
}

.list-group-item {
  position: relative;
}

.stretched-link::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.card {
  background-color: #f8f9fa;
  border: none;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.card-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.card-text {
  font-size: 1rem;
}

img.img-thumbnail {
  border: none;
  border-radius: 0.25rem;
}
